const requestInterval = (fn, delay) => {
  let start = new Date().getTime();
  let handle = {};

  function loop() {
    handle.value = requestAnimationFrame(loop);
    const current = new Date().getTime();
    const delta = current - start;
    if (delta >= delay) {
      fn.call();
      start = new Date().getTime();
    }
  }

  handle.value = requestAnimationFrame(loop);
  return handle;
};

const cancelInterval = interval => {
  cancelAnimationFrame(interval.value);
};

export { requestInterval, cancelInterval };
