import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link } from 'gatsby';

import Image from 'components/Image';

import { animate, quad } from 'utils/animation';
import { requestInterval, cancelInterval } from 'utils/interval';

import css from './carousel.module.scss';

const hideCard = elem => progress => {
  const scaleVal = 0.5 * progress;
  elem.style.opacity = 1 - progress;
  elem.style.transform = `scale(${1 - scaleVal})`;
};

const Carousel = ({ items }) => {
  const listRef = useRef();
  const marksRef = useRef();
  const elemWidth = useRef();
  const animations = useRef([]);

  const [selectedIx, setSelectedIdx] = useState(0);

  const onEndAnimation = () => {
    const elems = listRef.current.children;
    const firstElem = elems[0];
    const selectedElem = elems[1];
    const lastIdx = elems.length - 1;

    // Move to last position;
    listRef.current.insertBefore(firstElem, null);

    // Reset props
    firstElem.style.left = `${firstElem.offsetWidth * lastIdx +
      32 * lastIdx}px`;
    firstElem.style.transform = `scale(1)`;
    firstElem.style.opacity = 1;

    // Change selected mark;
    setSelectedIdx(Number(selectedElem.dataset.idx));
  };

  const pushCards = progress => {
    const elems = listRef.current.children;
    const deltaLeft = (elemWidth.current + 32) * progress;
    for (let i = 1; i < elems.length; i++) {
      const left = elemWidth.current * i + 32 * i;
      elems[i].style.left = `${left - deltaLeft}px`;
    }
  };

  const onResize = useCallback(() => {
    if (
      (window.innerWidth < 899 && elemWidth.current > 400) ||
      (window.innerWidth >= 899 && elemWidth.current < 400)
    ) {
      elemWidth.current = listRef.current.children[0].offsetWidth;
    }
  }, []);

  useEffect(() => {
    const items = listRef.current.children;
    elemWidth.current = items[0].offsetWidth;

    [].forEach.call(items, (item, idx) => {
      item.style.left = `${elemWidth.current * idx + 32 * idx}px`;
    });

    window.addEventListener('resize', onResize);
    const interval = requestInterval(() => {
      const firstElem = items[0];
      animations.current = [
        animate({
          duration: 750,
          draw: hideCard(firstElem),
        }),
        animate(
          { duration: 750, draw: pushCards, timing: quad },
          onEndAnimation
        ),
      ];
    }, 3000);

    return () => {
      // clear ongoing animations
      if (animations.current && animations.current.length) {
        animations.current.forEach(animation => {
          cancelAnimationFrame(animation.value);
        });
      }
      cancelInterval(interval);
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return (
    <div className={css.host}>
      <div className={css.list} ref={listRef}>
        {items.map((item, idx) => (
          <Link
            key={item.id}
            className={css.item}
            to={item.slug}
            data-idx={idx}
          >
            <Image src={item.image.url} alt={item.image.alt} className={css.image} />
            <h4 className={css.name}>{item.name}</h4>
          </Link>
        ))}
      </div>
      <div className={css.marks} ref={marksRef}>
        {items.map((item, idx) => (
          <div
            key={item.id}
            className={`${css.mark} ${
              selectedIx === idx ? css.selectedMark : ''
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
