import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

import css from './main-hero.module.scss';
import shared from 'styles/shared.module.scss';

const sliderNum = [1, 2, 3];

export default ({ title, description, buttonLabel, variations }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const renderString = activeIndex => {
    return variations[activeIndex].text;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(
        activeIndex === sliderNum.length - 1 ? 0 : activeIndex + 1
      );
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [activeIndex]);

  return (
    <section className={css.host}>
      <div className={css.images}>
        {variations.map((variation, idx) => (
          <div
            key={variation.text}
            className={`${activeIndex === idx ? css.showing : ''} ${
              css.imageContainer
            }`}
          >
            <Img
              className={`${activeIndex === idx ? css.showing : ''} ${
                css.imageContainer
              }`}
              fluid={variation.imageSharp.childImageSharp.fluid}
            />
          </div>
        ))}
      </div>
      <svg
        className={css.overlay}
        height="100%"
        width="100%"
        viewBox="0 0 760 895"
        preserveAspectRatio="none"
      >
        <path d="M0 0 L760 0 L730 895 L0 895 Z" />
      </svg>
      <div className={`${css.heroContent} ${shared.maxwidth}`}>
        <div className={css.sliderMarks}>
          {sliderNum.map((slider, idx) => (
            <div
              key={slider}
              className={`${css.sliderMark} ${
                activeIndex === idx ? css.active : ''
              }`}
            />
          ))}
        </div>
        <h1 className={css.heroTitle}>
          {RichText.asText(title)} <strong>{renderString(activeIndex)}</strong>
        </h1>
        <p className={css.heroDescription}>{RichText.asText(description)}</p>
        <a href="#technology">
          <button type="button" className={css.heroBtn}>
            {buttonLabel}
          </button>
        </a>
      </div>
      <div className={css.mobileGradient} />
    </section>
  );
};
