import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';
import { Helmet } from 'react-helmet';

import MainHero from 'components/MainHero';
import PlusIcon from 'components/Icons/PlusIcon';
import Carousel from 'components/Carousel';
import useSiteMetadata from 'hooks/useSiteMetadata';
import { linkResolver } from '../utils/link-resolver';

import css from './index.module.scss';
import shared from 'styles/shared.module.scss';

export default ({
  data: {
    prismic: { allApplications, allHomepages },
  },
  location,
}) => {
  const { siteUrl } = useSiteMetadata();

  const { edges: applications } = allApplications;
  const { edges: homepages } = allHomepages;

  const carouselItems = applications.map(edge => ({
    id: edge.node._meta.id,
    image: edge.node.hero_image,
    name: RichText.asText(edge.node.name),
    slug: `/applications/${edge.node._meta.uid}`,
  }));

  const homepage = homepages[0].node;
  const firstVariation = homepage.variations[0];

  return (
    <>
      <Helmet>
        <title>{`SwissDeCode`}</title>
        <meta description={RichText.asText(homepage.description)} />
        <meta property="og:title" content="SwissDeCode" />
        <meta
          property="og:description"
          content={RichText.asText(homepage.description)}
        />
        <meta property="og:url" content={`${siteUrl}${location.pathname}`} />
        <meta property="og:image" content={firstVariation.image.url} />
        <link rel="canonical" href={`${siteUrl}${location.pathname}`} />
      </Helmet>
      <MainHero
        title={homepage.title}
        description={homepage.description}
        buttonLabel={homepage.hero_button}
        variations={homepage.variations}
      />
      <section className={`${css.introBox} ${shared.maxwidth}`}>
        <div className={css.introBoxContent}>
          <h4 className={shared.sectionType}>
            {RichText.asText(homepage.vision_label)}
          </h4>
          <h2 className={css.introTitle}>
            {RichText.asText(homepage.vision_text)}
          </h2>
        </div>
      </section>
      <section className={`${css.solutionSection} ${shared.maxwidth}`}>
        <div className={css.sectionContent}>
          <h4 className={shared.sectionType}>
            {RichText.asText(homepage.solutions_label)}
          </h4>
          <h2 className={shared.sectionTitle}>
            {RichText.asText(homepage.solutions_title)}
          </h2>
          <p className={shared.sectionDescription}>
            {RichText.asText(homepage.solutions_text)}
            {homepage.links
              .filter(link => !!link.link_page)
              .map((link, idx) => (
                <b key={idx}>
                  {idx > 0 ? ', ' : ''}
                  <Link
                    to={linkResolver({
                      type: 'product',
                      uid: link.link_page._meta.uid,
                      categoryUid: link.link_page.product_category._meta.uid,
                    })}
                    className={css.linkItem}
                  >
                    {link.link_title}
                  </Link>
                </b>
              ))}
            .
          </p>
        </div>
        <div className={css.solutionImages}>
          <div className={css.solutionImageRow}>
            <Img
              className={`${css.solutionImage} ${css.solutionImage1}`}
              fluid={homepage.solutions_image_1Sharp.childImageSharp.fluid}
              alt={homepage.solutions_image_1.alt}
            />
            <Img
              className={`${css.solutionImage} ${css.solutionImage2}`}
              fluid={homepage.solutions_image_2Sharp.childImageSharp.fluid}
              alt={homepage.solutions_image_2.alt}
            />
          </div>
          <div className={css.solutionImageRow}>
            <Img
              className={`${css.solutionImage} ${css.solutionImage3}`}
              fluid={homepage.solutions_image_3Sharp.childImageSharp.fluid}
              alt={homepage.solutions_image_3.alt}
            />
            <Img
              className={`${css.solutionImage} ${css.solutionImage4}`}
              fluid={homepage.solutions_image_4Sharp.childImageSharp.fluid}
              alt={homepage.solutions_image_4.alt}
            />
          </div>
        </div>
      </section>
      <section className={css.differenceSection}>
        <svg
          className={css.differenceBackground}
          height="100%"
          width="100%"
          viewBox="0 0 1140 1000"
          preserveAspectRatio="none"
        >
          <path d="M0 0 L570 100 L1140 0 L1140 900 L570 1000 L0 900 Z" />
        </svg>
        <div className={css.differenceContent}>
          <h4 className={shared.sectionType}>
            {RichText.asText(homepage.difference_label)}
          </h4>
          <h2 className={shared.sectionTitle}>
            {RichText.asText(homepage.difference_title)}
          </h2>
          <p className={`${shared.sectionDescription} ${css.differenceIntro}`}>
            {RichText.asText(homepage.difference_text)}
          </p>
          <ul className={`${css.differenceList} ${shared.maxwidth}`}>
            {homepage.difference_points.map(difference_point => (
              <li
                key={difference_point.difference_point_image.alt}
                className={css.differenceItem}
              >
                <img
                  src={difference_point.difference_point_image.url}
                  alt={difference_point.difference_point_image.alt}
                />
                <h4 className={css.differenceTitle}>
                  {RichText.asText(difference_point.difference_point_title)}
                </h4>
                <p className={css.differenceDescription}>
                  {RichText.asText(difference_point.difference_point_text)}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section className={css.applicationsSection}>
        <div className={`${css.applicationsContainer} ${shared.maxwidth}`}>
          <div className={css.applicationsContent}>
            <h4 className={shared.sectionType}>
              {RichText.asText(homepage.applications_label)}
            </h4>
            <h2 className={shared.sectionTitle}>
              {RichText.asText(homepage.applications_title)}
            </h2>
            <p className={shared.sectionDescription}>
              {RichText.asText(homepage.applications_text)}
            </p>
            {homepage.applications_features.map(feature => (
              <div
                key={feature.applications_features_text}
                className={css.applicationsPoint}
              >
                <PlusIcon width={28} height={28} />
                <span>{feature.applications_features_text}</span>
              </div>
            ))}
          </div>
          <Carousel items={carouselItems} />
        </div>
      </section>
      {/* <section className={css.clientSection}>
      <div className={shared.maxwidth}>
        <div className={css.clientsTitle}>Who we work with</div>
        <div className={css.clientsList}></div>
      </div>
    </section> */}
    </>
  );
};

export const pageQuery = graphql`
  query landingPage {
    prismic {
      allApplications(sortBy: menu_order_ASC) {
        edges {
          node {
            _meta {
              id
              uid
            }
            name
            hero_image
          }
        }
      }
      allHomepages {
        edges {
          node {
            title
            description
            hero_button
            vision_label
            vision_text
            links {
              link_title
              link_page {
                _linkType
                ... on PRISMIC_Product {
                  _meta {
                    uid
                  }
                  product_category {
                    ... on PRISMIC_Product_category {
                      _meta {
                        uid
                      }
                    }
                  }
                }
              }
            }
            solutions_label
            solutions_title
            solutions_text
            solutions_image_1
            solutions_image_1Sharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 520) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            solutions_image_2
            solutions_image_2Sharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 520) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            solutions_image_3
            solutions_image_3Sharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 520) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            solutions_image_4
            solutions_image_4Sharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 520) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            variations {
              image
              imageSharp {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              text
            }
            applications_label
            applications_text
            applications_title
            applications_features {
              applications_features_text
            }
            technology_label
            technology_text
            technology_title
            difference_label
            difference_text
            difference_title
            difference_points {
              difference_point_image
              difference_point_text
              difference_point_title
            }
            _linkType
          }
        }
      }
    }
  }
`;
