const animate = ({ draw, timing, duration }, onEnd) => {
  let start = performance.now();
  let handle = {};

  const loop = time => {
    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) timeFraction = 1;

    let progress = timeFraction;

    if (timing) {
      progress = timing(timeFraction);
    }

    draw(progress);

    if (timeFraction < 1) {
      handle.value = requestAnimationFrame(loop);
    } else if (onEnd) {
      onEnd();
    }
  };

  handle.value = requestAnimationFrame(loop);
  return handle;
};

const quad = fraction => Math.pow(fraction, 2);

export { animate, quad };
